import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from "../components/seo"
import contact_page from '../../content/assets/page_images/contact_page.webp'
import Helmet from 'react-helmet';

const Contact = (props) => {
  const siteTitle = props.data.site.siteMetadata.title;
  const siteSlogan = props.data.site.siteMetadata.slogan;
  const frontmatter = {
    slug: props.location.pathname.replace(/[^\w\d-]/g, '')
  };

  return (
   <Layout title={siteTitle} slogan={siteSlogan}>
      <SEO
        title="Contact PW Team"
        description="Have some interesting idea or question regarding Power Watcher? We'd like to hear from you. Fill in the contact form below and we'll get in touch."
        image={contact_page}
        frontmatter={frontmatter}
      />
      <Helmet>
        <meta name="referrer" content="origin"/>
      </Helmet>
     <main>
      <h1 className="blog-heading">Contact</h1>
      <h2>How to Get in Touch</h2>
      <p>If you want to contact Yuriy Logvin, here’s how to do it:</p>
      <h3>Reach Out on Social Media</h3>
      <p>For most quick questions, you can contact Yuriy on social media because:</p>
      <ul>
        <li>The discussion can be beneficial for other people that have the same question</li>
        <li>Other smart people can add valuable advice to the conversation</li>
        <li>The brevity of social media conversations helps you save your time</li>
      </ul>
      <p>Yuriy is most active on Facebook, but you can also find him on Twitter.</p>
      <h3>Set Up a Call for In-Depth Questions</h3>
      <p>If you have a question that requires more than 280 characters in response, it’s better to schedule a call. </p>
      <p>Yuriy is always happy to do consulting calls — but he does charge for his time.</p>
      <p>To discuss things in details, talk specifics, please call this number: +38-067-573-77-22</p>
      <p>Alternatively, you can do this via Skype: logvinyuriy</p>
      <h3>Or Send an Email!</h3>
      <p>You can also send an email to yuriy.logvin@gmail.com. But be patient: He dones’t check it all that often, so the response might be a little bit delayed.</p>
      <h3>Or Send a Message</h3>
      <form className="pw-contact-form" method="post" action="https://formspree.io/yuriy.logvin@gmail.com">
        <p>
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" required/>
        </p>
        <p>
          <label htmlFor="email">Email</label>
          <input type="email" name="_replyto" id="email" required/>
        </p>
        <p>
          <label htmlFor="subject">Subject</label>
          <input type="text" name="subject" id="subject" required/>
        </p>
        <p>
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="5" required/>
        </p>
        <button className="pw-button" type="submit">Send</button>
      </form>

     </main>
   </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`